.menu-link {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.menu-link a {
    padding: 0.4rem 0.6rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
    color: white;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .menu-link a:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }
}
