.sentence-container {
    position: relative;
    width: 100%;
    min-height: 200px;
    margin-top: 40px;
    margin-bottom: 50px;
}

.sentence {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1);
    font-size: 42px;
    margin: 0;
}


.goingin {
    opacity: 1;
    transition-delay: 0.5s;
    transition-duration: 1s;
    transform: translateX(-50%) scale(1) translateY(0%);
}

.hidden {
    transition-duration: 0.5s;
    opacity: 0;
    transform: translateX(-50%) scale(0.8) translateY(0%);
}

@media (max-width: 700px) {
    .sentence-container {
    min-height: 150px;
    }
    .sentence {
        font-size: 32px;
        width: 100%;
    }
}
