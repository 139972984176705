.logoslider-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}
.logoslider-inner-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 auto;
        animation: scroll 20s linear infinite;
}

@keyframes scroll {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

.belt-container-1 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 0;
}

.belt-container-2 {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    z-index: 0;
}

.logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
