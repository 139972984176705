.headerContainer {
    color: rgb(var(--foreground-rgb));
}

.headerContainer * {
    margin: 0px;
    padding: 0px;
}

.navContainer {
    position: fixed;
    background-color: rgba(1,1,1,0.85);
    width: 100%;
    transform: translateY(-100%);
    transition-duration: 0.5s;
    z-index: 100;
}
.navInnerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    margin: 0 auto;
    max-width: var(--max-width);
}

.showMenu {
    transform: translateY(0);
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 6rem;
    min-height: 100vh;
    background: linear-gradient(
            to bottom,
            transparent,
            rgba(var(--background-end-rgb))
    )
    rgba(var(--background-start-rgb));
}



.description {
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    font-size: 0.85rem;
    max-width: var(--max-width);
    width: 100%;
    z-index: 2;
    font-family: var(--font-mono);
}

.description a {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.description p {
    position: relative;
    margin: 0;
    padding: 1rem;
}

.code {
    font-weight: 700;
    font-family: var(--font-mono);
}

.grid {
    display: grid;
    grid-template-columns: repeat(5, minmax(20%, auto));
    width: var(--max-width);
    max-width: 100%;
    font-family: Inter;
    text-align: left;
}

.card {
    padding: 1rem 1.2rem;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
    color: white;
    cursor: pointer;
}

.menuLogo {
    padding: 0.2rem 1.2rem;
    cursor: pointer;
}

.card span {
    display: inline-block;
    transition: transform 200ms;
}

.card h2 {
    font-weight: 600;
    margin-bottom: 0.7rem;
}

.card p {
    margin: 0;
    opacity: 0.6;
    font-size: 0.9rem;
    line-height: 1.5;
    max-width: 30ch;
}

.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 4rem 0;
}

.center h1 {
    font-weight: 600;
    z-index: 100;
}

.promoBtn {
    margin-top: 50px;
    border: 2px solid rgba(255,0,0,0.2);
    padding: 20px 20px 18px;
    color: #dadada;
    border-radius: 20px;
    background-color: rgba(0,0,0,0.6);
    font-family: Lato;
    cursor: pointer;
    animation-name: pulse;
    animation-duration: 1.5s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-fill-mode: none;
    animation-play-state: running;
}

@keyframes pulse {
    0% {
        background-color: rgba(0,0,0,0.3);
        color: #555762;
    }
    100% {
        background-color: rgba(240,0,0,0.3);
    }
}

.promoBtn:hover {
    color: #a4a4a4;
    animation-name: none;
    background-color: rgba(240,0,0,0.3);
}

.center::after {
    background: var(--primary-glow);
    width: 240px;
    height: 120px;
    z-index: 2;
}



.logo {
    position: relative;
}

.languageButtons {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    color: white;
}

.languageButtons a {
    color: white;
    padding: 10px;
    border-radius: var(--border-radius);
    background: rgba(var(--card-rgb), 0);
    border: 1px solid rgba(var(--card-border-rgb), 0);
    transition: background 200ms, border 200ms;
    color: white;
    cursor: pointer;
    font-size: 14px;
}
/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
    .card:hover {
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }

    .card:hover span {
        transform: rotate(90deg);
    }

    .languageButtons a:hover{
        background: rgba(var(--card-rgb), 0.1);
        border: 1px solid rgba(var(--card-border-rgb), 0.15);
    }
}

@media (prefers-reduced-motion) {
    .card:hover span {
        transform: none;
    }
}

/* Mobile */
@media (max-width: 700px) {

    .main {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem;
        min-height: 100vh;
        background: linear-gradient(
                to bottom,
                transparent,
                rgba(var(--background-end-rgb))
        )
        rgba(var(--background-start-rgb));
    }


    .navContainer {
     display: none;
    }

    .content {
        padding: 4rem;
    }

    .grid {
        grid-template-columns: 1fr;
        margin-bottom: 120px;
        max-width: 320px;
        text-align: center;
    }

    .card {
        padding: 1rem 2.5rem;
    }

    .card h2 {
        margin-bottom: 0.5rem;
    }

    .center {
        padding: 4rem 0 6rem;
    }

    .center::before {
        transform: none;
        height: 300px;
    }

    .description {
        font-size: 0.8rem;
    }

    .description a {
        padding: 1rem;
    }

    .description p,
    .description div {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .description p {
        align-items: center;
        padding: 2rem 1rem 1.4rem;
        border-radius: 0;
        border: none;
    }

    .description div {
        align-items: flex-start;
        z-index: 1;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 701px) and (max-width: 1120px) {
    .grid {
        grid-template-columns: repeat(2, 50%);
    }
}

@media (prefers-color-scheme: dark) {

}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}
