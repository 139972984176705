.lds-dual-ring {
    position: relative;
    display: inline-block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 9px;
    border-radius: 50%;
    border: 10px solid #22adff;
    border-color: #22adff transparent #22adff transparent;
    animation: lds-dual-ring 1.5s linear infinite;
}

.lds-dual-ring-dot {
    position: absolute;
    top: 0%;
    left: 0%;
    display: block;
    width: 64px;
    height: 64px;
}

.lds-dual-ring-dot:after {
    content: ' ';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background: #f34343;
    border-radius: 50%;
}

.loading-spinner__overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
