.footer-container {

    padding: 10px 10px;
    text-align: right;
    background: black;
    color: #afafaf;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;

}

.footer-container a {
    color: #afafaf;
    text-decoration: none;
}
