@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;400;700&family=Jura&family=Montserrat:wght@300;500&family=Source+Code+Pro:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background: url("images/laser3.jpg");
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.map {
  filter: saturate(0.2) contrast(0.75) invert();
  border: none!important;
}
.mapouter{position:relative;text-align:right;height:100%;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}

hr {
  background: #1b70ba;
  border: 1px solid #1b70ba;
  width: 200px;
}

:root {
  --max-width: 1100px;
  --border-radius: 5px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
  'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
  'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0, 0.6;
    --background-end-rgb: 0, 0, 0, 1;

    --primary-glow: radial-gradient(rgba(255, 5, 255, 0.4), rgba(255, 65, 0, 0));
    --secondary-glow: linear-gradient(
            to bottom right,
            rgba(1, 65, 255, 1),
            rgba(1, 65, 255, 0.1),
            rgba(255, 0, 0, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
            #ffffff80,
            #ffffff40,
            #ffffff30,
            #ffffff20,
            #ffffff10,
            #ffffff10,
            #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
}
* {
  box-sizing: border-box;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  background-size: cover;
  background-attachment: fixed;

}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

