.featured-container {
    width: 100%;
    background: white;
    position: relative;
    overflow: hidden;
}

.featured-left-skew {
    position: absolute;
    transform: rotate(45deg);
    display: block;
    background: #282c34;
    height: 800px;
    width: 800px;
    bottom: -100%;
    left: -10%;
    z-index: 1;
}


.featured-right-skew {
    position: absolute;
    transform: rotate(45deg);
    display: block;
    background: black;
    height: 800px;
    width: 800px;
    right: -10%;
    top: -100%;
    z-index: 1;
}

