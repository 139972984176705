.content-container {
    width: 100%;
    padding: 20px;
    font-family: Inter, sans-serif;
    color: #928f8f;
    height: auto !important;
    font-size: 14px;
}

.content-container.noheight {
    min-height: auto;
}

.content-container h1 {
    margin: 20px;
    color: white;
}

.full-height {
    min-height: 100vh;
    padding: 0px;
}

.with-feature {
    min-height: calc(100vh - 240px);

}

.full-width {
    max-width: 100%!important;
    width: 100%;
    padding: 20px 0px;
}


.white {
    color: #363636;
    background: white;
}

.white.content-container h1 {
    color: #232323;
}

.dimmed {
    background-color: rgba(0, 0, 0, 0.93);
}


.black {
    color: #ababab;
    background: black;
}

.grey {
    color: #ababab;
    background: #282c34;
}

.about {
    color: #ababab;
    background: rgba(10,10,10, 0.9);
    border: 1px solid rgba(200,200,200, 0.15);
}

.triangles-light {
    background: url('../../images/triangles_dark.png') no-repeat;
    background-size: cover;
    color: #8d8d8d;
}
.triangles-dark {
    background: url('../../images/triangles.png') no-repeat;
    background-size: cover;
    color: #8d8d8d;
}

.pixelized {
    background: url('../../images/pixelized.png') no-repeat;
    background-size: cover;
    color: #ababab;
}

.content {
    width: 100%;
    max-width: 1620px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

/* PRESS CONTENT */

.press-content {
    max-width: 960px;
}

p.two-columns {
    column-count: 2;
    column-gap: 20px;
    column-rule: 1px solid #343434;
}

p.three-columns {
    column-count: 3;
    column-gap: 20px;
    column-rule: 1px solid #343434;
}

/* PRESS CONTENT END*/

.content .image{
    padding: 20px;
    width: 100%;
}

.full-width {
    width: 100%;
    margin: 0 auto;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.full-width .content{
    padding: 0px;
}

.content.width1280 {
    max-width: 1280px;
    margin: 0 auto;
}

.content-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.contact-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin-top: 0px;
    align-items: center;
}

.column-25 {
    width: 25%;
    padding: 50px;
}

.column-33 {
    width: 33%;
    padding: 0px 20px;
    max-width: 33%;
}

.column-66 {
    width: 66%;
    padding: 0px 20px;
    max-width: 66%;
}

.column-50 {
   width: 50%;
   padding: 50px;
}

.column-50-stretch {
    width: 50%;
    padding: 50px;
    height: 100%;
}

.column-75 {
    width: 75%;
    padding: 50px;
}


.content-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.brick-right {
    flex: 3;
    background-color: rgba(40, 44, 53, 0.86);
    padding: 20px;
    color: #8c8c8f;
    margin-top: 25px;
    margin-bottom: 25px;
    border-radius: 10px;
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
}

.brick-left {
    flex: 3;
    background-color: rgba(33, 35, 38, 0.86);
    padding: 20px;
    color: #8c8c8f;
    margin-top: 25px;
    margin-bottom: 25px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: white;
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
}

.produkt1 {
    background: url('../../images/laser.jpg') no-repeat;
    background-size: cover;
    color: #ababab;
}

.produkt2 {
    background: url('../../images/laser2.jpg') no-repeat;
    background-size: cover;
    color: #ababab;
}

a {
    color: rgb(222, 34, 22);
    text-decoration: none;
}


.contact-address-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 75%;
    margin-top: 0px;
    align-items: center;
}


.contact-address-row > div{
    width: 75%;
}

.toggle-mute {
    margin-top: 20px;
}

.toggleBtn {
    cursor: pointer;
    color: #b7b7b7;
}
.toggleBtn:hover {
    color: white;
}

@media (max-width: 700px) {

    .content-container h1 {
        margin: 0px;
    }

    .brick-right, .brick-left {
        border-radius: 0px;
        border: none;
    }

    .content {
        width: 100%;
        max-width: 1620px;
        margin: 0 auto;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .content-row {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .column-25 {
        width: 100%;
        padding: 0px;
        max-width: none;
    }

    .column-50 {
        width: 100%;
        padding: 0px;
        max-width: none;
    }

    .column-66 {
        width: 100%;
        padding: 0px;
        max-width: none;
    }

    .column-33 {
        width: 100%;
        padding: 0px;
        max-width: none;
    }

    .column-50-stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
    }

    .column-25-stretch {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px;
        align-items: center;
        justify-content: center;
    }

    .column-75 {
        width: 100%;
        padding: 0px;
    }

    .contact-row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        margin-top: 0px;
        padding: 30px;
        align-items: center;
        background: rgba(100,100,100, 0.1);
        border: 1px solid rgba(200,200,200, 0.15);
        color: #b6b6b6;
    }

    .contact-address-row {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin-top: 0px;
        align-items: center;
    }


    .contact-address-row > div{
        width: 75%;
        margin-left: 0px!important;
    }

    /* PRESS CONTENT */

    .press-content {
        max-width: none;
    }

    p.two-columns {
        column-count: 1;
        column-gap: 0px;
        column-rule: none;
    }

    p.three-columns {
        column-count: 1;
        column-gap: 20px;
        column-rule: 1px solid #343434;
    }

    /* PRESS CONTENT END*/

}
